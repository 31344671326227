export default function() {
  const openSearchButton = document.querySelector('#open-search-bar');
  const closeSearchButton = document.querySelector('#close-search-bar');
  const searchBar = document.querySelector('#search-bar');

  if(!openSearchButton) return;

  openSearchButton.addEventListener('click', () => {
    openSearchButton.setAttribute('aria-expanded', 'true');
    searchBar.classList.remove('hidden');
    searchBar.classList.add('open');

    setTimeout(() => {
      searchBar.classList.remove('w-0');
      searchBar.classList.add('w-full');
      searchBar.querySelector('input').focus();
    });
  });

  closeSearchButton.addEventListener('click', () => {
    openSearchButton.setAttribute('aria-expanded', 'false');

    searchBar.addEventListener('transitionend', () => {
      searchBar.classList.add('hidden');
      searchBar.classList.remove('open');
    }, {once: true});

    searchBar.classList.remove('w-full');
    searchBar.classList.add('w-0');
  });
}
