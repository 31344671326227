import A11yDialog from 'a11y-dialog';

export default function() {
  document.querySelectorAll('.dialog-container').forEach(element => {
    const dialog = new A11yDialog(element);

    dialog.on('show', () => {
      document.body.classList.add('overflow-hidden');
    });

    dialog.on('hide', () => {
      document.body.classList.remove('overflow-hidden');
    });
  });
}
