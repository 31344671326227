export class LocationsMap {
  constructor () {
    this.markers = [];
    this.mapCore = null;
  }

  mapStyle (type) {
    let style = [];
    const light = [{
      'featureType': 'water',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#d3d3d3' }]
    }, {
      'featureType': 'transit',
      'stylers': [{ 'color': '#808080' }, { 'visibility': 'off' }]
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'visibility': 'on' }, { 'color': '#b3b3b3' }]
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#ffffff' }]
    }, {
      'featureType': 'road.local',
      'elementType': 'geometry.fill',
      'stylers': [{ 'visibility': 'on' }, { 'color': '#ffffff' }, { 'weight': 1.8 }]
    }, {
      'featureType': 'road.local',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#d7d7d7' }]
    }, {
      'featureType': 'poi',
      'elementType': 'geometry.fill',
      'stylers': [{ 'visibility': 'on' }, { 'color': '#ebebeb' }]
    }, {
      'featureType': 'administrative',
      'elementType': 'geometry',
      'stylers': [{ 'color': '#a7a7a7' }]
    }, {
      'featureType': 'road.arterial',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#ffffff' }]
    }, {
      'featureType': 'road.arterial',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#ffffff' }]
    }, {
      'featureType': 'landscape',
      'elementType': 'geometry.fill',
      'stylers': [{ 'visibility': 'on' }, { 'color': '#efefef' }]
    }, {
      'featureType': 'road',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#696969' }]
    }, {
      'featureType': 'administrative',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'visibility': 'on' }, { 'color': '#737373' }]
    }, {
      'featureType': 'poi',
      'elementType': 'labels.icon',
      'stylers': [{ 'visibility': 'off' }]
    }, {
      'featureType': 'poi',
      'elementType': 'labels',
      'stylers': [{ 'visibility': 'off' }]
    }, {
      'featureType': 'road.arterial',
      'elementType': 'geometry.stroke',
      'stylers': [{ 'color': '#d6d6d6' }]
    }, {
      'featureType': 'road',
      'elementType': 'labels.icon',
      'stylers': [{ 'visibility': 'off' }]
    }, {}, { 'featureType': 'poi', 'elementType': 'geometry.fill', 'stylers': [{ 'color': '#dadada' }] }];
    const dark = [{ elementType: 'geometry', stylers: [{ color: '#212121' }] }, {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }]
    }, { elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] }, {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#212121' }]
    }, {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [{ color: '#757575' }]
    }, {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    }, {
      featureType: 'administrative.land_parcel',
      stylers: [{ visibility: 'off' }]
    }, {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }]
    }, {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    }, { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#181818' }] }, {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    }, {
      featureType: 'poi.park',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#1b1b1b' }]
    }, { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#2c2c2c' }] }, {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#8a8a8a' }]
    }, {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#373737' }]
    }, {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#3c3c3c' }]
    }, {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [{ color: '#4e4e4e' }]
    }, {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    }, {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    }, { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#000000' }] }, {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#3d3d3d' }]
    }];
    const silver = [{ elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] }, {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }]
    }, { elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] }, {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f5f5' }]
    }, {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }]
    }, { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] }, {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    }, { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#e5e5e5' }] }, {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    }, {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    }, {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    }, {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }]
    }, {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    }, {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    }, {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    }, {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    }, { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#c9c9c9' }] }, {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    }];
    const night = [{ elementType: 'geometry', stylers: [{ color: '#242f3e' }] }, {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#746855' }]
    }, { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] }, {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    }, {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    }, { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#263c3f' }] }, {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#6b9a76' }]
    }, { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#38414e' }] }, {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#212a37' }]
    }, {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9ca5b3' }]
    }, {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#746855' }]
    }, {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#1f2835' }]
    }, {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#f3d19c' }]
    }, {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#2f3948' }]
    }, {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    }, { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#17263c' }] }, {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#515c6d' }]
    }, { featureType: 'water', elementType: 'labels.text.stroke', stylers: [{ color: '#17263c' }] }];
    const canwel = [{
      'featureType': 'administrative',
      'elementType': 'labels.text.fill',
      'stylers': [{ 'color': '#444444' }]
    }, {
      'featureType': 'landscape',
      'elementType': 'all',
      'stylers': [{ 'color': '#f2f2f2' }]
    }, { 'featureType': 'poi', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, {
      'featureType': 'road',
      'elementType': 'all',
      'stylers': [{ 'saturation': -100 }, { 'lightness': 45 }]
    }, {
      'featureType': 'road.highway',
      'elementType': 'all',
      'stylers': [{ 'visibility': 'simplified' }]
    }, {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [{ 'color': '#ffffff' }]
    }, {
      'featureType': 'road.arterial',
      'elementType': 'labels.icon',
      'stylers': [{ 'visibility': 'off' }]
    }, {
      'featureType': 'transit',
      'elementType': 'all',
      'stylers': [{ 'visibility': 'off' }]
    }, { 'featureType': 'water', 'elementType': 'all', 'stylers': [{ 'color': '#dde6e8' }, { 'visibility': 'on' }] }];

    if (type === 'dark') {
      style = dark;
    } else if (type === 'light') {
      style = light;
    } else if (type === 'silver') {
      style = silver;
    } else if (type === 'night') {
      style = night;
    } else if (type === 'canwel') {
      style = canwel;
    }

    return style;
  }

  mapCustomMarker (type) {
    let icon = null;

    // Converted from this site: https://base64.guru/converter/encode/image/svg
    const orangeCircle = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSAxM0MxMC4wODk5IDEzIDEzIDEwLjA4OTkgMTMgNi41QzEzIDIuOTEwMTUgMTAuMDg5OSAwIDYuNSAwQzIuOTEwMTUgMCAwIDIuOTEwMTUgMCA2LjVDMCAxMC4wODk5IDIuOTEwMTUgMTMgNi41IDEzWiIgZmlsbD0iI0Y0NzUyMSIvPgo8L3N2Zz4K',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const grayCircle = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSAxMy41MjM0QzEwLjA4OTkgMTMuNTIzNCAxMyAxMC42MTMzIDEzIDcuMDIzNDRDMTMgMy40MzM1OSAxMC4wODk5IDAuNTIzNDM4IDYuNSAwLjUyMzQzOEMyLjkxMDE1IDAuNTIzNDM4IDAgMy40MzM1OSAwIDcuMDIzNDRDMCAxMC42MTMzIDIuOTEwMTUgMTMuNTIzNCA2LjUgMTMuNTIzNFoiIGZpbGw9IiM2NjcwODAiLz4KPC9zdmc+Cg==',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const graySquare = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iMC4wMjM0Mzc1IiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9IiM2NjcwODAiLz4KPC9zdmc+Cg==',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const grayTriangle = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDEyLjUyMzRMLTIuMTg1NTdlLTA2IDEyLjUyMzRMNi43NDA3NCAwLjAyMzQzODdMMTQgMTIuNTIzNFoiIGZpbGw9IiM2NjcwODAiLz4KPC9zdmc+Cg==',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const blueCircle = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSAxMy41MjM0QzEwLjA4OTkgMTMuNTIzNCAxMyAxMC42MTMzIDEzIDcuMDIzNDRDMTMgMy40MzM1OSAxMC4wODk5IDAuNTIzNDM4IDYuNSAwLjUyMzQzOEMyLjkxMDE1IDAuNTIzNDM4IDAgMy40MzM1OSAwIDcuMDIzNDRDMCAxMC42MTMzIDIuOTEwMTUgMTMuNTIzNCA2LjUgMTMuNTIzNFoiIGZpbGw9IiMzMjRGQTAiLz4KPC9zdmc+Cg==',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const blueSquare = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iMC41NDY4NzUiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0iIzMyNEZBMCIvPgo8L3N2Zz4K',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const greenCircle = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSAxMy43NUMxMC4wODk5IDEzLjc1IDEzIDEwLjgzOTkgMTMgNy4yNUMxMyAzLjY2MDE1IDEwLjA4OTkgMC43NSA2LjUgMC43NUMyLjkxMDE1IDAuNzUgMCAzLjY2MDE1IDAgNy4yNUMwIDEwLjgzOTkgMi45MTAxNSAxMy43NSA2LjUgMTMuNzVaIiBmaWxsPSIjMDA4MjZFIi8+Cjwvc3ZnPgo=',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const greenSquare = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iMC43NzM0MzgiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0iIzAwODI2RSIvPgo8L3N2Zz4K',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const greenTriangle = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjUgMTMuMjVMLTIuMTg1NTdlLTA2IDEzLjI1TDYuNSAwLjc1MDAwMUwxMy41IDEzLjI1WiIgZmlsbD0iIzAwODI2RSIvPgo8L3N2Zz4K',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const greenHexagon = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMC43NUwxMy45MjgyIDQuNzVWMTIuNzVMNyAxNi43NUwwLjA3MTc5NjkgMTIuNzVWNC43NUw3IDAuNzVaIiBmaWxsPSIjMDA4MjZFIi8+Cjwvc3ZnPgo=',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    const greenTriangleDown = {
      url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNTAwMDAxIC0xLjE4MDIxZS0wNkwxNCAwTDcuNSAxMi41TDAuNTAwMDAxIC0xLjE4MDIxZS0wNloiIGZpbGw9IiMwMDgyNkUiLz4KPC9zdmc+Cg==',
      anchor: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(14, 14)
    };

    if (type === 'head-office') {
      icon = orangeCircle;
    } else if (type === 'division-head-office') {
      icon = grayCircle;
    } else if (type === 'regional-office') {
      icon = graySquare;
    } else if (type === 'private-timberlands') {
      icon = grayTriangle;
    } else if (type === 'distribution-centre') {
      icon = blueCircle;
    } else if (type === 'truss-plant-distribution-centre') {
      icon = blueSquare;
    } else if (type === 'treating-distribution-facility') {
      icon = greenCircle;
    } else if (type === 'sawmill-treating-distrib-facility') {
      icon = greenSquare;
    } else if (type === 'planer-mill-treating-distrib-facility') {
      icon = greenTriangle;
    } else if (type === 'truss-plant-treating-distrib-facility') {
      icon = greenHexagon;
    } else if (type === 'post-pole-plant') {
      icon = greenTriangleDown;
    }

    return icon;
  }

  newMap (el) {
    // vars
    const mapStyling = this.mapStyle('canwel');
    mapStyling.push({ featureType: 'poi.business', stylers: [{ visibility: 'off' }] });
    const args = {
      zoom: 16,
      center: new window.google.maps.LatLng(0, 0),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      styles: mapStyling,
      disableDefaultUI: true,
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM
      },
      mapTypeControl: false,
      zoomControl: true
    };

    return new window.google.maps.Map(el, args);
  }

  setMarkers (locationData) {
    const markers = locationData.querySelectorAll('.js-location-item:not(.hidden) .marker');

    // add markers
    markers.forEach((el) => {
      this.addMarker(el);
    });

    this.centerMap();
  }

  addMarker (markerEl) {
    if (markerEl.getAttribute('data-lat') && markerEl.getAttribute('data-lng')) {
      const latlng = new window.google.maps.LatLng(markerEl.getAttribute('data-lat'), markerEl.getAttribute('data-lng'));
      const icon = this.mapCustomMarker(markerEl.getAttribute('data-facility'));

      // create marker
      const marker = new window.google.maps.Marker({
        position: latlng,
        map: this.mapCore,
        optimized: false,
        icon: icon
      });

      // add to array
      this.markers.push(marker);

      window.google.maps.event.addListener(marker, 'click', () => {
        document.querySelectorAll('.js-location-item').forEach((el) => {
          el.classList.remove('active');
          el.querySelector('button').setAttribute('aria-expanded', 'false');
        });

        markerEl.parentNode.parentNode.classList.add('active');
        markerEl.parentNode.parentNode.querySelector('button').setAttribute('aria-expanded', 'true');

        document.getElementById('js-location-data').scrollTop = markerEl.parentNode.parentNode.offsetTop -
          document.getElementById('js-location-data').offsetTop;

        const bounds = new window.google.maps.LatLngBounds();
        const latlng = marker.getPosition();
        bounds.extend(latlng);

        this.mapCore.setCenter(bounds.getCenter());
        this.mapCore.setZoom(16);
      });
    } else {
      return false;
    }
  }

  centerMap () {
    // vars
    const bounds = new window.google.maps.LatLngBounds();

    // loop through all markers and create bounds
    this.markers.forEach((marker) => {
      const latlng = new window.google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (this.markers.length === 1) {
      // set center of map
      this.mapCore.setCenter(bounds.getCenter());
      this.mapCore.setZoom(16);
    } else {
      // fit to bounds
      this.mapCore.fitBounds(bounds);
    }
  }

  setCenterWithSelected (locationData) {
    const markers = locationData.querySelectorAll('.marker');
    const bounds = new window.google.maps.LatLngBounds();
    let count = 0;

    markers.forEach((el) => {
      if (el.getAttribute('data-lat') && el.getAttribute('data-lng')) {
        const latlng = new window.google.maps.LatLng(el.getAttribute('data-lat'), el.getAttribute('data-lng'));
        bounds.extend(latlng);
        count++;
      }
    });

    if (markers.length === 1 || count === 1) {
      // set center of map
      this.mapCore.setCenter(bounds.getCenter());
      this.mapCore.setZoom(16);
    } else {
      // fit to bounds
      this.mapCore.fitBounds(bounds, 100);
    }
  }

  setMapOnAll () {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(this.mapCore);
    }
  }

  clearMarkers () {
    this.setMapOnAll();
  }

  deleteMarkers () {
    this.clearMarkers();
    this.markers = [];
  }

  mapInit () {
    if (!document.getElementById('js-location-map')) { return; }

    this.mapCore = this.newMap(document.getElementById('js-location-map'));
  }
}
