export default function filterDocuments(yearButtons, CSSclass) {
  if (yearButtons.length == 0) return;

  const activeYear = yearButtons[0].dataset.year;
  const documentsList = document.querySelectorAll(`${CSSclass} .document-card`);
  const yearDropdown = document.querySelector(`${CSSclass} select`);

  const filterDocsByYear = function (year) {
    documentsList.forEach(document => {
      if(document.dataset.documentYear != year) {
        if(!document.classList.contains('hidden')) {
          document.classList.add('hidden');
        }
      } else {
        if(document.classList.contains('hidden')) {
          document.classList.remove('hidden');
        }
      }
    });
  };

  filterDocsByYear(activeYear);

  yearButtons.forEach(button => {
    button.addEventListener('click', (event) => {
      let selectedYear = event.currentTarget.dataset.year;
      filterDocsByYear(selectedYear);
      yearDropdown.value = selectedYear;
    });
  });

  yearDropdown.addEventListener('change', () => {
    filterDocsByYear(yearDropdown.value);
  });
}
