import 'swiper/css/bundle';
import '/assets/styles/main.pcss';

import LazyLoad from 'vanilla-lazyload';
import Alpine from 'alpinejs';

import dialog from './components/dialog.js';
import dropdown from './components/dropdown.js';
import form from './components/forms.js';
import hamburger from './components/hamburger.js';
import header from './components/header.js';
import jobsList from './components/jobsList.js';
import locations from './components/locations.js';
import newsletter from './components/newsletter.js';
import products from './components/products.js';
import searchBar from './components/search-bar.js';
import slideToggle from './components/slide-toggle.js';
import slider from './components/slider.js';

window.lazyloadInstance = new LazyLoad();
window.Alpine = Alpine;

Alpine.start();

document.addEventListener('DOMContentLoaded', () => {
  dialog();
  dropdown();
  form();
  hamburger();
  header();
  jobsList();
  locations();
  newsletter();
  products();
  searchBar();
  slideToggle();
  slider();
});
