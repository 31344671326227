import Swiper from 'swiper';
import { Navigation, Pagination, Mousewheel } from 'swiper/modules';
import filterDocuments from './documents';

export default function slider() {

  const initYearOptions = function (CSSclass) {
    const yearButtons = document.querySelectorAll(`${CSSclass} .swiper .swiper-slide button`);
    filterDocuments(yearButtons, CSSclass);
  };

  new Swiper('.swiper-product-images', {
    modules: [Pagination],
    a11y: true,
    slidesPerView: 1.05,
    spaceBetween: 16,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3,
      renderBullet: function (index) {
        return `<button type="button" class="swiper-pagination-bullet" aria-label="Image ${index + 1}"></button>`;
      }
    },
    breakpoints: {
      640: {
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1024: {
        slidesPerView: 'auto',
        spaceBetween: 32,
      }
    }
  });

  document.querySelectorAll('.swiper-product-swatches').forEach(el => {
    new Swiper(el, {
      modules: [Navigation],
      a11y: true,
      spaceBetween: 0,
      enabled: false,
      slidesPerView: "auto",
      navigation: {
        nextEl: el.parentElement.querySelector('.swiper-button-next'),
        prevEl: el.parentElement.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        1024: {
          spaceBetween: 32,
          enabled: true,
        }
      }
    });
  });

  document.querySelectorAll('.swiper-related-products').forEach(el => {
    new Swiper(el, {
      modules: [Navigation],
      a11y: true,
      slidesPerView: 2.1,
      spaceBetween: 16,
      navigation: {
        nextEl: el.parentElement.querySelector('.swiper-button-next'),
        prevEl: el.parentElement.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        }
      }
    });
  });

  new Swiper('.swiper-home', {
    modules: [Pagination],
    a11y: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  });

  new Swiper('.swiper-facts', {
    modules: [Pagination, Mousewheel],
    a11y: true,
    slidesPerView: 'auto',
    mousewheel: true,
    direction: "horizontal",
    spaceBetween: 24,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      1024: {
        enabled: false,
        slidesPerView: 3,
        spaceBetween: 32
      }
    }
  });

  new Swiper('.swiper-timeline', {
    modules: [Navigation, Mousewheel],
    a11y: true,
    slideToClickedSlide: true,
    mousewheel: true,
    direction: "horizontal",
    navigation: {
      nextEl: '.swiper-timeline ~ .swiper-button-next',
      prevEl: '.swiper-timeline ~ .swiper-button-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 5,
      },
      1280: {
        slidesPerView: 6,
      },
      1440: {
        slidesPerView: 7,
      }
    }
  });

  new Swiper('.swiper-employees', {
    modules: [Navigation, Pagination],
    a11y: true,
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: {
      nextEl: '.swiper-employees ~ .swiper-button-next',
      prevEl: '.swiper-employees ~ .swiper-button-prev',
    },
    pagination: {
      el: '.swiper-employees ~ .swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 2,
    }
  });

  new Swiper('.swiper-testimonials', {
    modules: [Navigation, Pagination],
    a11y: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-testimonials ~ .swiper-button-next',
      prevEl: '.swiper-testimonials ~ .swiper-button-prev',
    },
    pagination: {
      el: '.swiper-testimonials ~ .swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 2,
    }
  });

  document.querySelectorAll('.swiper-images').forEach(el => {
    new Swiper(el, {
      modules: [Pagination],
      a11y: true,
      spaceBetween: 20,
      slidesPerView: 'auto',
      pagination: {
        el: el.querySelector('.swiper-pagination'),
        clickable: true,
      }
    });
  });

  new Swiper('.swiper-pressReleases', {
    modules: [Navigation],
    a11y: true,
    navigation: {
      nextEl: '.swiper-pressReleases ~ .swiper-button-next',
      prevEl: '.swiper-pressReleases ~ .swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
      1440: {
        slidesPerView: 5,
      }
    },
    on: {
      afterInit: () => initYearOptions('.pressReleases')
    }
  });

  new Swiper('.swiper-financialReports', {
    modules: [Navigation],
    a11y: true,
    navigation: {
      nextEl: '.swiper-financialReports ~ .swiper-button-next',
      prevEl: '.swiper-financialReports ~ .swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
      1440: {
        slidesPerView: 5,
      }
    },
    on: {
      afterInit: () => initYearOptions('.financialReports')
    }
  });


}
