export default function () {
  const header = document.getElementById('header');
  const hamburgerMenu = document.querySelector('.hamburger-container');
  const languageRedirection = document.getElementById("language-redirection");
  const activeClass = 'active';
  const scrolledDownClass = 'scrolled-down';
  const scrollPosition = 10;
  let isOver = false;

  const onScrolling = function () {
    let top = scrollPosition;
    if (languageRedirection) {
      top += languageRedirection.offsetHeight;
    }
    if (window.scrollY > top) {
      if (!isOver) {
        header.classList.add(activeClass);
      }
      header.classList.add(scrolledDownClass);
      hamburgerMenu.classList.add(scrolledDownClass);
    } else {
      if (!isOver) {
        header.classList.remove(activeClass);
      }
      header.classList.remove(scrolledDownClass);
      hamburgerMenu.classList.remove(scrolledDownClass);
    }
  };

  document.addEventListener('scroll', onScrolling);
  onScrolling();

  if (languageRedirection) {
    const closeBtn = languageRedirection.querySelector(".close-button");
    closeBtn.addEventListener("click", () => {
      const d = new Date();
      d.setTime(d.getTime() + (365*24*60*60*1000));
      document.cookie = "domanLanguageRedirection=" + closeBtn.dataset.currentSite + ";expires=" + d.toUTCString() + ";path=/";

      languageRedirection.style.display = 'none';
    });
  }
}
