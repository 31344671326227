export default function() {
  let formInputs = document.querySelectorAll('.fui-input');

  if(formInputs.length > 0) {
    const checkInputs = function () {
      formInputs.forEach((input) => {
        let value = input.value;

        if(value) {
          input.classList.add('has-value');
        } else {
          if(input.classList.contains('has-value')) {
            input.classList.remove('has-value');
          }
        }
      });
    }

    formInputs.forEach((input) => {
      input.addEventListener('blur', checkInputs);
    });

    window.addEventListener('load', checkInputs);
  }
}
