export default function () {
  const filtersForm = document.querySelector('[data-product-filters]');
  const productsResults = document.querySelector('[data-product-results]');
  const filtersSelection = document.querySelector('[data-product-filters-selection]');
  const resetBtn = document.querySelector('[data-product-filters-reset]');
  const showFiltersBtn = document.querySelector('[data-show-products-filters]');
  const hideFiltersBtn = document.querySelector('[data-hide-products-filters]');
  const hideFiltersBtnBottom = document.querySelector('[data-hide-product-filters-bottom]');

  const activeClass = 'active';

  if (!filtersForm || !productsResults) return;

  const checkboxes= filtersForm.querySelectorAll('input[type="checkbox"]');
  const products= productsResults.querySelectorAll('[data-product-category-id]');

  const filterProducts = () => {
    const categoriesIds = [];
    filtersSelection.innerHTML = '';
    checkboxes.forEach(el => {
      if (el.checked) {
        categoriesIds.push(el.value);
        filtersSelection.innerHTML += `
          <span class="inline-flex items-center gap-1 tag">
            ${el.parentNode.innerText}
            <button type="button" class="align-text-bottom" data-clear-filter="${el.value}" aria-label="${window.labels.removeFilter} ${el.parentNode.innerText}">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 6L6 10" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
                <path d="M6 6L10 10" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
              </svg>
            </button>
          </span>
        `;
      }
    });
    filtersSelection.querySelectorAll('[data-clear-filter]').forEach(btnEl => {
      btnEl.addEventListener('click', () => {
        filtersForm.querySelector(`input[value='${btnEl.dataset.clearFilter}']`).checked = false;
        filterProducts();
      });
    });

    const formData = new FormData(filtersForm);
    const params = new URLSearchParams(formData);
    window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));

    products.forEach(productEl => {
      const productCategoriesIds = productEl.dataset.productCategoryId.split(",");
      if (productCategoriesIds.some(id => categoriesIds.includes(id)) || categoriesIds.length === 0) {
        productEl.classList.remove('hidden');
      } else {
        productEl.classList.add('hidden');
      }
    });
  };

  checkboxes.forEach(checkboxEl => {
    checkboxEl.addEventListener('change', () => {
      filterProducts();
    });
  });

  filterProducts();

  resetBtn.addEventListener('click', () => {
    checkboxes.forEach(el => {
      el.checked = false;
      filterProducts();
    });
  });

  const onHideTransitionend = () => {
    filtersForm.style.display = 'none';
  };

  const onShowTransitionend = () => {
    filtersForm.style.height = 'auto';
  };

  const showFilters = () => {
    filtersForm.removeEventListener('transitionend', onHideTransitionend);
    filtersForm.addEventListener('transitionend', onShowTransitionend, { once: true });

    filtersForm.style.display = 'block';
    filtersForm.style.height = '0px';

    setTimeout(() => {
      filtersForm.classList.add(activeClass);
      filtersForm.style.height = filtersForm.scrollHeight + 'px';
    }, 0);

    showFiltersBtn.setAttribute('aria-expanded', 'true');
    showFiltersBtn.setAttribute('title', showFiltersBtn.dataset.closeTitle);
    showFiltersBtn.classList.add(activeClass);
    hideFiltersBtn.classList.add(activeClass);
  };

  const hideFilters = () => {
    filtersForm.removeEventListener('transitionend', onShowTransitionend);
    filtersForm.addEventListener('transitionend', onHideTransitionend, { once: true });

    filtersForm.style.display = 'block';
    filtersForm.style.height = filtersForm.scrollHeight + 'px';

    setTimeout(() => {
      filtersForm.style.height = '0px';
      filtersForm.classList.remove(activeClass);
    }, 0);

    showFiltersBtn.setAttribute('aria-expanded', 'false');
    showFiltersBtn.setAttribute('title', showFiltersBtn.dataset.openTitle);
    showFiltersBtn.classList.remove(activeClass);
    hideFiltersBtn.classList.remove(activeClass);
  };

  showFiltersBtn.addEventListener('click', () => {
    if (showFiltersBtn.classList.contains(activeClass)) {
      hideFilters();
    } else {
      showFilters();
    }
  });

  hideFiltersBtn.addEventListener('click', () => {
    hideFilters();
  });

  hideFiltersBtnBottom.addEventListener('click', () => {
    hideFilters();
  });
}
