import { LocationsMap } from './locations-map.js';

export default function () {
  const locationData = document.getElementById('js-location-data');

  if (!locationData) return;

  const locationsMap = new LocationsMap();
  locationsMap.mapInit();

  locationsMap.setMarkers(locationData);

  const locationItems = locationData.querySelectorAll('.js-location-item');

  locationItems.forEach(locationItem => {
    const buttonElement = locationItem.querySelector('button');

    const onClick = () => {
      locationItems.forEach(el => {
        if (el !== locationItem) {
          el.classList.remove('active');
        }
      });

      if (locationItem.classList.contains('active')) {
        buttonElement.setAttribute('aria-expanded', 'false');
        locationItem.classList.remove('active');
        locationsMap.centerMap();
      } else {
        buttonElement.setAttribute('aria-expanded', 'true');
        locationItem.classList.add('active');
        locationsMap.setCenterWithSelected(locationItem);
      }
    };

    buttonElement.addEventListener('click', onClick);
  });

  document.querySelector('#divisionsFilter').addEventListener('change', (event) => {
    event.currentTarget.value;

    document.querySelectorAll('[data-divisions]').forEach(el => {
      const divisions = JSON.parse(el.dataset.divisions);
      if (event.currentTarget.value === '' || divisions.indexOf(parseInt(event.currentTarget.value)) !== -1) {
        el.classList.remove('hidden');
      } else {
        el.classList.add('hidden');
      }
    });

    locationsMap.deleteMarkers();
    locationsMap.setMarkers(locationData);
  });
}
