export default function () {
  const header = document.getElementById('header');
  const hamburger = document.querySelector('#hamburger');
  const mobileMenu = document.querySelector('#hamburger-menu');
  const activeClass = 'active';

  if (!hamburger || !mobileMenu) {
    return;
  }

  const onTransitionend = () => {
    mobileMenu.style.display = 'none';
  };

  hamburger.addEventListener('click', () => {
    if (hamburger.classList.contains(activeClass)) {
      mobileMenu.classList.remove(activeClass);
      mobileMenu.addEventListener('transitionend', onTransitionend, { once: true });

      hamburger.setAttribute('aria-expanded', 'false');
      hamburger.setAttribute('title', hamburger.dataset.openTitle);
      hamburger.classList.remove(activeClass);

      header.classList.remove('submenu-active');

      document.body.classList.remove('overflow-hidden');
    } else {
      mobileMenu.removeEventListener('transitionend', onTransitionend);
      mobileMenu.style.display = 'block';
      setTimeout(() => {
        mobileMenu.classList.add(activeClass);
      }, 0);

      hamburger.setAttribute('aria-expanded', 'true');
      hamburger.setAttribute('title', hamburger.dataset.closeTitle);
      hamburger.classList.add(activeClass);

      header.classList.add('submenu-active');

      document.body.classList.add('overflow-hidden');
    }
  });

  const onSlideInEnd = (panel) => {
    return function onslideInTransitionEnd() {
      panel.style.transform = 'translateX(0)';
    };
  };

  const onSlideOutEnd = (panel) => {
    return function onslideInTransitionEnd() {
      panel.style.display = 'none';
      panel.style.transform = 'translateX(100%)';
    };
  };

  const removeAllEventListeners = (panel) => {
    panel.removeEventListener('transitionend', onSlideOutEnd);
    panel.removeEventListener('transitionend', onSlideInEnd);
  };

  const slideIn = (button, panel) => {
    button.classList.add(activeClass);
    button.setAttribute('aria-expanded', 'true');

    panel.style.transform = 'translateX(100%)';
    panel.style.display = 'block';

    setTimeout(() => {
      panel.style.transform = 'translateX(0)';
    }, 0);

    removeAllEventListeners(panel);
    panel.addEventListener('transitionend', onSlideInEnd(panel), { once: true });
  };

  const slideOut = (button, panel) => {
    button.classList.remove(activeClass);
    button.setAttribute('aria-expanded', 'false');

    panel.style.transform = 'translateX(0)';

    setTimeout(() => {
      panel.style.transform = 'translateX(100%)';
    }, 0);

    removeAllEventListeners(panel);
    panel.addEventListener('transitionend', onSlideOutEnd(panel), { once: true });
  };

  Array.from(document.querySelectorAll('[data-slide-in]')).forEach(button => {
    const panel = document.querySelector(button.dataset.slideIn);
    const backButton = document.querySelector(`${button.dataset.slideIn} .back-button`);
    if (!button.classList.contains(activeClass)) {
      panel.style.display = 'none';
    }
    panel.classList.add('overflow-hidden', 'transition-all', 'duration-300');

    button.addEventListener('click', () => {
      if (!button.classList.contains(activeClass)) {
        slideIn(button, panel);
      }
    });

    backButton.addEventListener('click', () => {
      if (button.classList.contains(activeClass)) {
        slideOut(button, panel);
      }
    });

    button.setAttribute('aria-expanded', button.classList.contains(activeClass) ? 'true' : 'false');
  });
}
