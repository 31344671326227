export default function() {
  const jobs = document.querySelectorAll('.job');
  const jobDropdowns = document.querySelectorAll('#job-filters select');
  const filtersForm = document.querySelector('[data-jobs-filters]');
  const resetBtn = document.querySelector('[data-jobs-filters-reset]');
  const showFiltersBtn = document.querySelector('[data-show-jobs-filters]');
  const hideFiltersBtn = document.querySelector('[data-hide-jobs-filters]');
  const hideFiltersBtnBottom = document.querySelector('[data-hide-jobs-filters-bottom]');
  const activeClass = 'active';
  let selectedFilters = [];

  if (jobs.length == 0) return;

  jobDropdowns.forEach(dropdown => {
    dropdown.addEventListener('change', () => {
      getSelectedFilters();
    });
  });

  const getSelectedFilters = function () {
    selectedFilters = [];
    jobDropdowns.forEach(dropdown => {
      if (dropdown.value !== '') {
        selectedFilters.push(dropdown.value);
      }
    });
    filterJobs(selectedFilters);
  };

  const filterJobs = function (selectedFilters) {
    jobs.forEach(job => {
      let data = job.dataset.filters;
      let jobFitSelection = selectedFilters.every(i => data.includes(i));

      if(jobFitSelection) {
        if(job.classList.contains('hidden')) {
          job.classList.remove('hidden');
        }
      } else {
        if(!job.classList.contains('hidden')) {
          job.classList.add('hidden');
        }
      }
    });
  };

  resetBtn.addEventListener('click', () => {
    jobDropdowns.forEach(el => {
      el.value = '';
      getSelectedFilters();
    });
  });

  const onHideTransitionend = () => {
    filtersForm.style.display = 'none';
  };

  const onShowTransitionend = () => {
    filtersForm.style.height = 'auto';
  };

  const showFilters = () => {
    filtersForm.removeEventListener('transitionend', onHideTransitionend);
    filtersForm.addEventListener('transitionend', onShowTransitionend, { once: true });

    filtersForm.style.display = 'block';
    filtersForm.style.height = '0px';

    setTimeout(() => {
      filtersForm.classList.add(activeClass);
      filtersForm.style.height = filtersForm.scrollHeight + 'px';
    }, 0);

    showFiltersBtn.setAttribute('aria-expanded', 'true');
    showFiltersBtn.setAttribute('title', showFiltersBtn.dataset.closeTitle);
    showFiltersBtn.classList.add(activeClass);
    hideFiltersBtn.classList.add(activeClass);
  };

  const hideFilters = () => {
    filtersForm.removeEventListener('transitionend', onShowTransitionend);
    filtersForm.addEventListener('transitionend', onHideTransitionend, { once: true });

    filtersForm.style.display = 'block';
    filtersForm.style.height = filtersForm.scrollHeight + 'px';

    setTimeout(() => {
      filtersForm.style.height = '0px';
      filtersForm.classList.remove(activeClass);
    }, 0);

    showFiltersBtn.setAttribute('aria-expanded', 'false');
    showFiltersBtn.setAttribute('title', showFiltersBtn.dataset.openTitle);
    showFiltersBtn.classList.remove(activeClass);
    hideFiltersBtn.classList.remove(activeClass);
  };

  showFiltersBtn.addEventListener('click', () => {
    if (showFiltersBtn.classList.contains(activeClass)) {
      hideFilters();
    } else {
      showFilters();
    }
  });

  hideFiltersBtn.addEventListener('click', () => {
    hideFilters();
  });

  hideFiltersBtnBottom.addEventListener('click', () => {
    hideFilters();
  });
}
