export default function () {
  const contactForm = document.getElementById("constant-contact-form");

  if (!contactForm) return;

  const isFrench = document.documentElement.getAttribute("lang").substring(0,2) === 'fr';

  const observer = new MutationObserver(() => {
    const emailInput = contactForm.querySelector("input[type='email']");
    const label = contactForm.querySelector("label");
    if (emailInput) {
      const onChange = () => {
        if (emailInput.value === '') {
          label.classList.remove("!hidden");
        } else {
          label.classList.add("!hidden");
        }
      };

      emailInput.addEventListener('change', onChange);
      emailInput.addEventListener('input', onChange);
      emailInput.addEventListener('focus', onChange);
      emailInput.addEventListener('blur', onChange);

      if (isFrench) {
        label.innerText = "Courriel";

        contactForm.querySelector("#success_message_1 .ctct-form-header").innerText = "Merci de vous être inscrit";
        contactForm.querySelector("#success_message_1 .ctct-form-text").innerText = "Vous pouvez vous désinscrire à tout moment en utilisant le lien de désabonnement présent en bas de chaque courriel.";

        contactForm.querySelector("#error_message_1 .ctct-form-errorMessage").innerText = "Désolé, nous n'avons pas pu finaliser votre inscription. Veuillez nous contacter pour résoudre ce problème.";

        contactForm.querySelector("#network_error_message_1 .ctct-form-errorMessage").innerText = "L'opération a expiré, veuillez réessayer.";
      }

      observer.disconnect();
    }
  });

  const config = { attributes: true, childList: true, subtree: true };
  observer.observe(contactForm, config);
}
